import React, { useState } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";

import MobileMenuNavDropdownMenuLink from "./MobileMenuNavDropdownMenuLink";
import DropdownMenuChevronIcon from "./DropdownMenuChevronIcon";

function MobileMenuNavDropdownMenu({ title, linkDataItems }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleDropdownMenu() {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }

  return (
    <div
      className={classNames("mobileMenu__dropdownMenu", {
        "mobileMenu__dropdownMenu--open": isOpen,
      })}
    >
      <div
        onClick={handleToggleDropdownMenu}
        className="mobileMenu__dropdownMenu__titleContainer"
      >
        <span className="mobileMenu__dropdownMenu__title">{title}</span>
        <DropdownMenuChevronIcon className="mobileMenu__dropdownMenu__chevronIcon" />
      </div>
      <div className="mobileMenu__dropdownMenu__listWrapper">
        <ul>
          {linkDataItems.map((linkDataItem) => (
            <MobileMenuNavDropdownMenuLink
              key={linkDataItem.href}
              href={linkDataItem.href}
              text={linkDataItem.text}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

MobileMenuNavDropdownMenu.propTypes = {
  title: PropTypes.string,
  linkDataItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default MobileMenuNavDropdownMenu;
