import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function HeaderHamburger({ onClick, isOpen }) {
  const hamburgerClasses = classnames("hamburger", { open: isOpen });

  return (
    <button className={hamburgerClasses} onClick={onClick}>
      <div className="hamburger__container">
        <span className="hamburger__item hamburger__item--1"></span>
        <span className="hamburger__item hamburger__item--2"></span>
        <span className="hamburger__item hamburger__item--3"></span>
        <span className="hamburger__item hamburger__item--4"></span>
      </div>
    </button>
  );
}

HeaderHamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default HeaderHamburger;
