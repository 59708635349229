import React from "react";

import facebookImage from "../../../images/fb.png";
import instagramImage from "../../../images/instagram.svg";
import twitterImage from "../../../images/twitter.svg";
import linkedinImage from "../../../images/linkedin-white.svg";

function FooterSocials() {
  return (
    <div className="footer__socials">
      <a
        href="https://www.instagram.com/accesto_team/"
        className="footer__social"
        rel="nofollow noreferrer"
        target="_blank"
        witdh="16"
        height="16"
      >
        <img
          src={instagramImage}
          alt="Accesto Instagram Profile"
          className="footer__icon"
          witdh="16"
          height="16"
        />
      </a>
      <a
        href="https://twitter.com/accestopl"
        className="footer__social"
        rel="nofollow noreferrer"
        target="_blank"
      >
        <img
          src={twitterImage}
          alt="Accesto Twitter Profile"
          className="footer__icon"
          witdh="16"
          height="16"
        />
      </a>   
      <a
        href="https://www.linkedin.com/company/accesto-sp-z-o-o-"
        className="footer__social"
        rel="nofollow noreferrer"
        target="_blank"
      >
        <img
          src={linkedinImage}
          alt="Accesto Linkedin Profile"
          className="footer__icon"
          witdh="16"
          height="16"
        />
      </a>
      <a
        href="https://www.facebook.com/accesto"
        className="footer__social"
        rel="nofollow noreferrer"
        target="_blank"
      >
        <img src={facebookImage} alt="Facebook" className="footer__icon" />
      </a>
    </div>
  );
}

export default FooterSocials;
