import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Button from "../button/Button";
import Time from "../time/Time";

function ArticleListItem({ title, date, timeToRead, image, excerpt, slug }) {
 return (
  <li>
   <article className="articles__article">
    <Link to={slug} className="articles__top">
     <div className="articles__imageContainer">
      <Img className="articles__image" fluid={image} loading="eager" title={title} alt={title}/>
     </div>
     <div className="articles__overlay"></div>
    </Link>
    <div className="articles__content">
     <div>
      <header className="articles__header">
       <Time className="articles__time" date={date} timeToRead={timeToRead} />
       <Link to={slug}>
        <h2 className="articles__heading">{title}</h2>
       </Link>
      </header>
      <p className="articles__text">{excerpt}</p>
     </div>
     <Button text to={slug} className="articles__readMoreButton">
      Read more
     </Button>
    </div>
   </article>
  </li>
 );
}

ArticleListItem.propTypes = {
 title: PropTypes.string.isRequired,
 excerpt: PropTypes.string.isRequired,
 date: PropTypes.string.isRequired,
 timeToRead: PropTypes.number.isRequired,
 image: PropTypes.object.isRequired,
 slug: PropTypes.string.isRequired,
};

export default ArticleListItem;
