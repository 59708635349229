import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function FooterMenuLink({ href, to, children }) {
  return (
    <li className="footer__item">
      {href ? (
        <a href={href} className="footer__link">
          {children}
        </a>
      ) : (
        <Link to={to} className="footer__link">
          {children}
        </Link>
      )}
    </li>
  );
}

FooterMenuLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};

export default FooterMenuLink;
