export const knowledgeDropdownMenuLinkDataItems = [
  {
    href: "https://accesto.com/blog/",
    text: "Our Blog",
  },
  {
    href: "https://phpatscale.substack.com/",
    text: "Newsletter: PHP At Scale",
  },
  {
    href: "https://accesto.com/books/docker-deep-dive/",
    text: "E-Book: Docker Deep Dive",
  },
];
