import React from "react";

import MobileMenuNavDropdownMenu from "./MobileMenuNavDropdownMenu";
import HeaderMobileMenuNavLink from "./MobileMenuNavLink";

import { servicesDropdownMenuLinkDataItems } from "../../../constants/servicesDropdownMenuLinkDataItems";
import { knowledgeDropdownMenuLinkDataItems } from "../../../constants/knowledgeDropdownMenuLinkDataItems";

function HeaderMobileMenuNav() {
  return (
    <nav className="mobileMenu__nav">
      <MobileMenuNavDropdownMenu
        title="Services"
        titleHref="https://accesto.com/services/"
        linkDataItems={servicesDropdownMenuLinkDataItems}
      />
      <HeaderMobileMenuNavLink href="https://accesto.com/case-study/">
        Case studies
      </HeaderMobileMenuNavLink>
      <HeaderMobileMenuNavLink href="https://accesto.com/about/">
        About us
      </HeaderMobileMenuNavLink>
      <MobileMenuNavDropdownMenu
        title="Knowledge"
        linkDataItems={knowledgeDropdownMenuLinkDataItems}
      />
      <HeaderMobileMenuNavLink href="https://accesto.com/career/">
        Career
      </HeaderMobileMenuNavLink>
    </nav>
  );
}

export default HeaderMobileMenuNav;
