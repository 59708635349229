import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function HeaderMobileMenuNavLink({ href, to, children }) {
  return href ? (
    <a href={href} className="mobileMenu__link">
      {children}
    </a>
  ) : (
    <Link to={to} className="mobileMenu__link">
      {children}
    </Link>
  );
}

HeaderMobileMenuNavLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};

export default HeaderMobileMenuNavLink;
