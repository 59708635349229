import React from "react";

import FooterMenuLink from "./MenuLink";

function FooterMenu() {
  return (
    <div className="footer__col">
      <span className="footer__heading">Menu</span>
      <div className="footer__textLinks">
        <ul>
          <FooterMenuLink href="https://accesto.com/about/">
            About us
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/case-study/">
            Portfolio
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/career/">
            Careers
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/contact/">
            Contact us
          </FooterMenuLink>
        </ul>
      </div>
    </div>
  );
}

export default FooterMenu;
