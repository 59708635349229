import React from "react";
import classnames from "classnames";

import HeaderTopBarNav from "./TopBarNav";
import Button from "../../button/Button";
import useShouldHeaderBeSmall from "../../../hooks/useShouldHeaderBeSmall";
import logo from "../../../images/logo-secondary.svg";

function HeaderTopBar() {
  const shouldHeaderBeSmall = useShouldHeaderBeSmall();
  const topbarClasses = classnames("topbar", {
    "topbar--scroll": shouldHeaderBeSmall,
  });
  const topbarImgClasses = classnames("topbar__img", "logo__img", {
    "topbar__img--scroll": shouldHeaderBeSmall,
  });

  return (
    <div className={topbarClasses}>
      <div className="topbar__container container">
        <a
          href="https://accesto.com/"
          className="logo topbar__logo"
          title="Accesto Homepage - Make your SaaS scalable!"
        >
          <img
            src={logo}
            alt="Blue Accesto logo"
            className={topbarImgClasses}
          />
        </a>
        <HeaderTopBarNav />
        <Button
          secondary
          href="https://accesto.com/contact/"
          className="topbar__btn"
        >
          Contact us
        </Button>
      </div>
    </div>
  );
}

export default HeaderTopBar;
