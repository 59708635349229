export function getArticleArrayFromQuery(queryArticles) {
  const { edges } = queryArticles;

  const articleArray = edges.map(({ node }) => {
    const { fields, id, frontmatter, timeToRead, excerpt } = node;
    const { title, tags, heroImage, categories, dateUpdated } =
      frontmatter;
    const { date, slug } = fields;
    const { childImageSharp, publicURL } = heroImage;
    const image = childImageSharp.fluid;

    return {
      id,
      timeToRead,
      excerpt,
      title,
      tags,
      image,
      imagePublicURL: publicURL,
      date,
      dateUpdated,
      slug,
      categories: categories || [],
    };
  });

  return articleArray;
}

export function getRelatedArticles({
  articles,
  currSlug,
  tags,
  categories,
  limit,
}) {
  const articlesToCheck = articles.filter((art) => art.slug !== currSlug);
  const identityMap = {};

  articlesToCheck.forEach((art) => {
    identityMap[art.slug] = { art, points: 0 };

    art.categories.forEach((category) => {
      if (categories.includes(category)) {
        identityMap[art.slug].points += 2;
      }
    });

    art.tags.forEach((tag) => {
      if (tags.includes(tag)) {
        identityMap[art.slug].points += 1;
      }
    });
  });

  const identityArray = Object.keys(identityMap).map((key) => identityMap[key]);
  const relatedArticlesArray = identityArray
    .sort((a, b) => b.points - a.points)
    .slice(0, limit)
    .map(({ art }) => art);

  return relatedArticlesArray;
}

export function getFirstParagraphText(html) {
  const paragraphInnerHTML = html.substring(
    html.indexOf("<p>") + 3,
    html.indexOf("</p>")
  );

  const text = paragraphInnerHTML.replace(/<\/?[^>]+(>|$)/g, "");

  return text;
}
