import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FooterInfo from "./Info";
import FooterMenu from "./Menu";
import FooterContact from "./Contact";
import FooterNetwork from "./Network";
import FooterSocials from "./Socials";
import FooterServices from "./Services";
import FooterKnowledge from './Knowledge';

function Footer({ moved }) {
  const footerClass = classnames("footer", { "footer--moved": moved });

  return (
    <footer className={footerClass}>
      <div className="footer__container container">
        <FooterInfo />
        <FooterMenu />
        <FooterServices />
        <FooterKnowledge isVisibleOnlyOnMobile />
        <div className="footer__col footer__col--withoutPadding">
          <FooterKnowledge isColumnInnerSection />
          <FooterContact isColumnInnerSection />
        </div>
        <div className="footer__col footer__col--wide">
          <FooterNetwork />
          <FooterSocials />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  moved: PropTypes.bool,
};

export default Footer;
