const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Example: 2017-07-25 --->  25 July 2017
export function formatDate(date) {
  const year = date.slice(0, 4);
  const monthNumber = parseInt(date.slice(5, 7));
  const month = monthNames[monthNumber - 1];
  const day = date.slice(8, 11);

  return `${day} ${month} ${year}`;
}
