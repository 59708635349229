import React from 'react';

import classNames from 'classnames';

import FooterMenuLink from "./MenuLink";


const FooterKnowledge = ({isVisibleOnlyOnMobile, isColumnInnerSection}) => (
    <div className={classNames("footer__col", {"footer__col__innerSection": isColumnInnerSection}, {"footer__col__content--bigScreen": !isVisibleOnlyOnMobile }, {"footer__col--onlyOnMobile": isVisibleOnlyOnMobile })}>
        <span className="footer__heading">Knowledge</span>
        <div className="footer__textLinks">
            <ul>
                <FooterMenuLink href="/blog/">
                    Our Blog
                </FooterMenuLink>
                <FooterMenuLink href="https://phpatscale.substack.com/">
                    Newsletter: PHP at Scale
                </FooterMenuLink>
                <FooterMenuLink href="https://accesto.com/books/docker-deep-dive/">
                    Book: Docker Deep Dive
                </FooterMenuLink>
            </ul>
        </div>
    </div>
);

export default FooterKnowledge