export const servicesDropdownMenuLinkDataItems = [
  {
    href: "https://accesto.com/services/",
    text: "All Services",
    isVisibleOnlyOnMobile: true,
  },
  {
    href: "https://accesto.com/services/application-modernization-services/",
    text: "Application modernization",
  },
  {
    href: "https://accesto.com/services/php-refactoring-services/",
    text: "PHP code refactoring",
  },
  {
    href: "https://accesto.com/services/php-development-services/",
    text: "PHP development",
  },
  {
    href: "https://accesto.com/services/symfony-development-services/",
    text: "Symfony development",
  },
  {
    href: "https://accesto.com/services/laravel-development-services/",
    text: "Laravel development",
  },
];
