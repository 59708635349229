import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ArticleListItem from "./ArticleListItem";

function ArticleList({ className, articles }) {
 const listClassname = classnames("articles__list", className);

 return (
  <ul className={listClassname}>
   {articles.map(({ id, ...rest }) => (
    <ArticleListItem key={id} {...rest} />
   ))}
  </ul>
 );
}

ArticleList.propTypes = {
 className: PropTypes.string,
 articles: PropTypes.arrayOf(
  PropTypes.shape({
   title: PropTypes.string.isRequired,
   excerpt: PropTypes.string.isRequired,
   date: PropTypes.string.isRequired,
   dateUpdated: PropTypes.string,
   timeToRead: PropTypes.number.isRequired,
   image: PropTypes.object.isRequired,
   imagePublicURL: PropTypes.string,
   slug: PropTypes.string.isRequired,
  })
 ).isRequired,
};

export default ArticleList;
