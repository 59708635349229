import React from "react";
import PropTypes from "prop-types";

import HeaderMobileMenuNav from "./MobileMenuNav";
import Button from "../../button/Button";
import logo from "../../../images/logo.svg";

function HeaderMobileMenu({ containerHeight, menuHeight }) {
  return (
    <div className="mobileMenu" style={{ height: menuHeight }}>
      <div
        className="mobileMenu__container container"
        style={{ height: containerHeight }}
      >
        <div className="mobileMenu__top">
          <a href="https://accesto.com/">
            <img
              src={logo}
              alt="Accesto logo"
              className="logo__img mobileMenu__logo"
              width="117"
              height="42"
            />
          </a>
        </div>
        <HeaderMobileMenuNav />
        <div className="mobileMenu__btns">
          <Button
            primary
            href="https://accesto.com/contact/"
            className="mobileMenu__btn"
          >
            Contact us
          </Button>
        </div>
      </div>
    </div>
  );
}

HeaderMobileMenu.propTypes = {
  containerHeight: PropTypes.string.isRequired,
  menuHeight: PropTypes.string.isRequired,
};

export default HeaderMobileMenu;
