import React from "react";

function BlogNav({ children }) {
  return (
    <div className="blogNav">
      <div className="blogNav__container container">{children}</div>
    </div>
  );
}

export default BlogNav;
