import React from "react";

import PropTypes from "prop-types";

function MobileMenuNavDropdownMenuLink({ href, text }) {
  return (
    <li className="mobileMenu__dropdownMenu__item">
      <a href={href} className="mobileMenu__dropdownMenu__link">
        {text}
      </a>
    </li>
  );
}

MobileMenuNavDropdownMenuLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
};

export default MobileMenuNavDropdownMenuLink;
