import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import useSiteMetadata from "../../hooks/useSiteMetadata";
import {getUrl} from '../../utils/url';

function SEO({
  title,
  description,
  pathname,
  showTwitterDescription,
  showTwitterTitle,
  canonicalHref,
  children,
  twitterImage,
  ogImage,
}) {
  const {
    titleSufix,
    defaultDescription,
    keyWords,
    siteUrl,
  } = useSiteMetadata();

  const finalTitle = title + titleSufix;
  const finalDescription = description || defaultDescription;

  return (
    <Helmet title={finalTitle}  htmlAttributes={{
    lang: 'en',
  }}>
      {canonicalHref && <link rel="canonical" href={canonicalHref} />}

      <meta name="description" content={finalDescription} />
      <meta name="keywords" content={keyWords} />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:site_name" content="Accesto Blog" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:url" content={getUrl(siteUrl, pathname)} />
      {ogImage && <meta property="og:image" content={ogImage} />}

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@accestoPL" />
      {showTwitterTitle && (
        <meta property="twitter:title" content={finalTitle} />
      )}
      {showTwitterDescription && (
        <meta property="twitter:description" content={finalDescription} />
      )}
      {twitterImage && <meta property="twitter:image" content={twitterImage} />}

      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  canonicalHref: PropTypes.string,
  showTwitterDescription: PropTypes.bool,
  showTwitterTitle: PropTypes.bool,
  description: PropTypes.string,
  twitterImage: PropTypes.string,
  ogImage: PropTypes.string,
};

export default SEO;
