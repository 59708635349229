import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";

function HeaderTopBarNavLink({ href, to, className, isActive, children }) {
  const linkClass = classNames("topbar__link", className, {
    "topbar__link--active": isActive,
  } );

  return href ? (
    <a href={href} className={linkClass}>
      {children}
    </a>
  ) : (
    <Link to={to} className={linkClass}>
      {children}
    </Link>
  );
}

HeaderTopBarNavLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

export default HeaderTopBarNavLink;
