import React from "react";

import PropTypes from "prop-types";

function TopBarNavDropdownMenuLink({ href, text }) {
  return (
    <li className="topbar__dropdownMenu__item">
      <a href={href} className="topbar__dropdownMenu__link">
        {text}
      </a>
    </li>
  );
}

TopBarNavDropdownMenuLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
};

export default TopBarNavDropdownMenuLink;
