import React, { useState, useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

import SearchForm from "./Form";
import SearchResult from "./Result";
import useClickOutside from "../../../../hooks/useClickOutside";

const index = { name: `Pages`, title: `Pages` };

function Search() {
 const rootRef = useRef(null);
 const [hasFocus, setFocus] = useState(false);

 useClickOutside(rootRef, handleClickOutside);


 const algoliaClient = algoliasearch(
     process.env.GATSBY_ALGOLIA_APP_ID,
     process.env.GATSBY_ALGOLIA_SEARCH_KEY
 );

 const searchClient = {
  search(requests) {
   const shouldSearch = requests.some(({ params: { query }}) => query !== '');
   if (shouldSearch) {
    return algoliaClient.search(requests);
   }
   return Promise.resolve({
    results: [{ hits: [] }],
   });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
 };

 function handleFocus() {
  setFocus(true);
 }

 function handleClickOutside() {
  setFocus(false);
 }

 return (
  <div className="search" ref={rootRef}>
   <InstantSearch searchClient={searchClient} indexName={index.name}>
    <SearchForm onFocus={handleFocus} isOpen={hasFocus} />
    <SearchResult hasFocus={hasFocus} index={index} />
   </InstantSearch>
  </div>
 );
}

export default Search;
