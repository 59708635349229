import React from "react";

import logo from "../../../images/logo.svg";

function FooterInfo() {
  return (
    <div className="footer__col footer__col--logo">
      <a href="https://accesto.com/" className="logo">
        <img src={logo} alt="Accesto logo" className="logo__img" 
          width="131" height="47"/>
      </a>
      <span className="footer__about">
        Web Development Company Poland
        <br /><br />
        Web Application Development<br />
        & Refactoring Since 2010
      </span>
      <address className="footer__address">
        Plac Solny 15 <br /> 
        50-062 Wrocław, Poland
      </address>
    </div>
  );
}

export default FooterInfo;
