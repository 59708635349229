import React, { useState } from "react";

import HeaderTopBar from "./TopBar";
import HeaderHamburger from "./Hamburger";
import HeaderMobileMenu from "./MobileMenu";

function Header() {
 const [state, setState] = useState({
  mobileOpen: false,
  containerHeight: "0px",
  menuHeight: "0%",
 });

 const { mobileOpen, containerHeight, menuHeight } = state;

 function handleHamburgerClick() {
  if (mobileOpen) closeMobileNav();
  else openMobileNav();
 }

 function closeMobileNav() {
  setState({ ...state, mobileOpen: false, menuHeight: "0%" });
 }

 function openMobileNav() {
  const newContainerHeight = `${window.innerHeight}px`;

  setState({
   mobileOpen: true,
   containerHeight: newContainerHeight,
   menuHeight: "100%",
  });
 }

 return (
  <header className="header">
   <HeaderTopBar />
   <HeaderHamburger isOpen={mobileOpen} onClick={handleHamburgerClick} />
   <HeaderMobileMenu
    containerHeight={containerHeight}
    menuHeight={menuHeight}
   />
  </header>
 );
}

export default Header;
