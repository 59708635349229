import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Header from "./header/Header";
import Footer from "./footer/Footer";

function Layout({ children, footerMoved }) {
  return (
    <Fragment>
      <Header />
      {children}
      <Footer moved={footerMoved} />
    </Fragment>
  );
}

Footer.propTypes = {
  footerMoved: PropTypes.bool,
};

export default Layout;
