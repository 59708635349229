import React from "react";

import classNames from "classnames";

function FooterContact({isColumnInnerSection}) {
  return (
    <div className={classNames("footer__col footer__contacts", {"footer__col__innerSection": isColumnInnerSection})}>
      <span className="footer__heading">Contact</span>
      <div className="footer__contact">
        <div className="footer__contactItem">
          <span className="footer__link">Email</span>
          <a
            href="mailto:hello+footer@accesto.com"
            className="footer__link footer__link--secondary"
            id="footer_mail"
          >
            hello@accesto.com
          </a>
        </div>
        <div className="footer__contactItem">
          <span className="footer__link">Phone</span>
          <a
            href="tel:+48717575077"
            className="footer__link footer__link--secondary"
            id="footer_phone"
          >
            +48 717 575 077
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterContact;
