import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connectSearchBox } from "react-instantsearch-dom";

function BlogNavSearch({ refine, currentRefinement, onFocus, isOpen }) {
 const inputClassName = classnames("search__input", {
  "search__input--open": isOpen,
 });

 function handleChange(e) {
  refine(e.target.value);
 }

 function handleSubmit(e) {
  e.preventDefault();
 }

 return (
  <form onSubmit={handleSubmit} className="search__form">
   <input
    onChange={handleChange}
    value={currentRefinement}
    onFocus={onFocus}
    type="text"
    className={inputClassName}
    autoComplete="off"
    spellCheck="false"
   />
  </form>
 );
}

BlogNavSearch.propTypes = {
 onFocus: PropTypes.func.isRequired,
 isOpen: PropTypes.bool.isRequired,
};

export default connectSearchBox(BlogNavSearch);
