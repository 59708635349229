import React from "react";
import PropTypes from "prop-types";

import { formatDate } from "../../utils/date";

function Time({ date, timeToRead, className, dateUpdated }) {

  if(dateUpdated) {
    return (
      <time className={className}>
        {formatDate(date)} (updated: {formatDate(dateUpdated)}) - {timeToRead} min 
      </time>
    );
  } else {
    return (
      <time className={className}>
        {formatDate(date)} - {timeToRead} min 
      </time>
    );
  }
}

Time.propTypes = {
  date: PropTypes.string.isRequired,  
  timeToRead: PropTypes.number.isRequired,
  className: PropTypes.string,
  dateUpdated: PropTypes.string,
};

export default Time;
