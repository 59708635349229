import { useState, useEffect } from "react";

function useShouldHeaderBeSmall() {
  const [shouldHeaderBeSmall, setShouldHeaderBeSmall] = useState(
    shouldHeaderBeNowSmall()
  );

  function shouldHeaderBeNowSmall() {
    if (typeof window === "undefined") return;

    const width = window.matchMedia("(max-width: 992px)");
    if (!width.matches) {
      if (window.pageYOffset > 0) return true;
      return false;
    }

    return false;
  }

  function checkScroll() {
    const newState = shouldHeaderBeNowSmall();
    setShouldHeaderBeSmall(newState);
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", checkScroll);
    };
    // eslint-disable-next-line
  }, []);

  return shouldHeaderBeSmall;
}

export default useShouldHeaderBeSmall;
