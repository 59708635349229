import React from "react";

import TopBarNavDropdownMenu from "./TopBarNavDropdownMenu";
import HeaderTopBarNavLink from "./TopBarNavLink";

import { servicesDropdownMenuLinkDataItems } from "../../../constants/servicesDropdownMenuLinkDataItems";
import { knowledgeDropdownMenuLinkDataItems } from "../../../constants/knowledgeDropdownMenuLinkDataItems";

function HeaderTopBarNav() {
  const desktopServicesDropdownMenuLinkDataItems = servicesDropdownMenuLinkDataItems.filter(servicesDropdownMenuLinkDataItem => !servicesDropdownMenuLinkDataItem.isVisibleOnlyOnMobile);
  
  const desktopKnowledgeDropdownMenuLinkDataItems = knowledgeDropdownMenuLinkDataItems.filter(knowledgeDropdownMenuLinkDataItem => !knowledgeDropdownMenuLinkDataItem.isVisibleOnlyOnMobile);

  return (
    <nav className="topbar__menu">
      <TopBarNavDropdownMenu
        title="Services"
        titleHref="https://accesto.com/services/"
        linkDataItems={desktopServicesDropdownMenuLinkDataItems}
      />
      <HeaderTopBarNavLink href="https://accesto.com/case-study/">
        Case studies
      </HeaderTopBarNavLink>
      <HeaderTopBarNavLink href="https://accesto.com/about/">
        About us
      </HeaderTopBarNavLink>
      <TopBarNavDropdownMenu
        title="Knowledge"
        linkDataItems={desktopKnowledgeDropdownMenuLinkDataItems}
      />
      <HeaderTopBarNavLink href="https://accesto.com/career/">
        Career
      </HeaderTopBarNavLink>
    </nav>
  );
}

export default HeaderTopBarNav;

