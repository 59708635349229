import React from "react";

import FooterMenuLink from "./MenuLink";

function FooterServices() {
  return (
    <div className="footer__col">
      <span className="footer__heading">Services</span>
      <div className="footer__textLinks">
        <ul>
          <FooterMenuLink href="https://accesto.com/services/">
            All Services
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/services/application-modernization-services/">
            Application modernization
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/services/php-refactoring-services/">
            PHP code refactoring
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/services/php-development-services/">
            PHP development
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/services/symfony-development-services/">
            Symfony development
          </FooterMenuLink>
          <FooterMenuLink href="https://accesto.com/services/laravel-development-services/">
            Laravel development
          </FooterMenuLink>
          {/* while expertise page is hidden */}
          {/* <FooterMenuLink href="https://accesto.com/expertise/">
            Enhancing existing software
          </FooterMenuLink> */}
        </ul>
      </div>
    </div>
  );
}

export default FooterServices;
