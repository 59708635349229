import { useEffect } from "react";

const events = [`mousedown`, `touchstart`];

export default (ref, onClickOutside) => {
 function isOutside(element) {
  return !ref.current || !ref.current.contains(element);
 }

 function onClick(event) {
  if (isOutside(event.target)) {
   onClickOutside();
  }
 }

 useEffect(() => {
  for (const event of events) {
   document.addEventListener(event, onClick);
  }

  return () => {
   for (const event of events) {
    document.removeEventListener(event, onClick);
   }
  };
 });
};
