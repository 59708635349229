import React from "react";

import itcornerImage from "../../../images/itcorner.svg";
import clutchImage from "../../../images/clutchsmall.png";

function FooterNetwork() {
  return (
    <div className="footer__network">
      <a
        href="http://itcorner.org.pl/"
        rel="nofollow noreferrer"
        target="_blank"
        className="footer__org footer__org--text"
        id="itcorner_logo"
      >
        <span className="footer__member">Member of</span>
        <img className="footer__logo" src={itcornerImage} alt="ITCorner" />
      </a>
      <a
        href="https://clutch.co/profile/accesto"
        rel="nofollow noreferrer"
        className="footer__org"
        target="_blank"
        id="clutch_logo"
      >
        <img
          src={clutchImage}
          alt="Accesto Clutch Profile"
          className="footer__logo footer__logo--clutch"
          witdh="85"
          height="92"
        />
      </a>
    </div>
  );
}

export default FooterNetwork;
