import React from "react";
import PropTypes from "prop-types";

import HeaderTopBarNavLink from "./TopBarNavLink";
import TopBarNavDropdownMenuLink from "./TopBarNavDropdownMenuLink";
import DropdownMenuChevronIcon from "./DropdownMenuChevronIcon";

function TopBarNavDropdownMenu({ title, titleHref, linkDataItems }) {
  return (
    <div className="topbar__dropdownMenu">
      <div className="topbar__dropdownMenu__titleContainer">
        <HeaderTopBarNavLink href={titleHref} className="topbar__dropdownMenu__title">
          {title}
        </HeaderTopBarNavLink>
        <DropdownMenuChevronIcon className="topbar__dropdownMenu__chevronIcon" />
      </div>
      <div className="topbar__dropdownMenu__listWrapper">
        <ul className="topbar__dropdownMenu__itemList">
          {linkDataItems.map((linkDataItem) => (
            <TopBarNavDropdownMenuLink
              key={linkDataItem.href}
              href={linkDataItem.href}
              text={linkDataItem.text}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

TopBarNavDropdownMenu.propTypes = {
  title: PropTypes.string.isRequired,
  titleHref: PropTypes.string.isRequired,
  linkDataItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TopBarNavDropdownMenu;