import React from "react";
import { Link } from "gatsby";
import { Highlight, Snippet } from "react-instantsearch-dom";

function Hit({ hit }) {
 return (
  <Link className="search__hit" to={hit.slug}>
   <div className="search__heading">
    <Highlight attribute="title" hit={hit} tagName="mark" />
   </div>

   <p className="search__snippet">
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
   </p>
  </Link>
 );
}

export default Hit;
