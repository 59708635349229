import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Hits, Index, connectHits, connectStateResults} from "react-instantsearch-dom";

import Hit from "./Hit";

function areHitsInitial(hits) {
 if (hits.length === 0) return false;

 const [firstHit] = hits;
 const { title, slug, excerpt } = firstHit._highlightResult;

 const titleMatchedWordsLen = title.matchedWords.length;
 const slugMatchedWordsLen = slug.matchedWords.length;
 const excerptMatchedWordsLen = excerpt.matchedWords.length;

 return (
  titleMatchedWordsLen + slugMatchedWordsLen + excerptMatchedWordsLen === 0
 );
}

const SearchResult = connectStateResults(({ searchState, index, hasFocus}) => {
 const resultClassname = classnames("search__result", {
  "search__result--show": hasFocus && searchState && searchState.query,
 });

 return searchState && searchState.query ? (
  <div className={resultClassname}>
   <Index indexName={index.name}>
    <Hits hitComponent={Hit} />
   </Index>
  </div>
 ) : "";
});

SearchResult.propTypes = {
 index: PropTypes.shape({
  name: PropTypes.string.isRequired,
 }).isRequired,
 hasFocus: PropTypes.bool.isRequired,
};

export default SearchResult;
